/**
 * Switch content types' value to match the data received from contentful full-text search
 * @param {string} type - The article's original content type
 * @returns - the new content type, formatted to match the corresponding object key
 */
export const formatContentTypes = (type) => {
  switch (type) {
    case 'articles':
      return 'article'
    case 'formation':
      return 'description'
    default:
      return type;
  }
}

/**
 * Go through the article's content to find an excerpt containing the search value
 * @param {object} fields - Data received from contentful full-type search
 * @param {string} contentType - The content's type needed to look for an excerpt containing the search value
 * @param {string} searchValue - The search value
 * @returns an excerpt from the article details, containing the search value
 */
export const findExcerptFromSearchValue = (fields, contentType, searchValue) => {
  const type = formatContentTypes(contentType);
  const data = fields[type] || fields.content;
  return data?.content?.find(x => x.content?.length && x.content?.find(y => y.value?.includes(searchValue)))?.content.find(x => x.value.includes(searchValue)).value;
}

/**
 * @param {object} fields - The article's entire data
 * @param {string} searchValue - The search value
 * @param {string} excerpt - The paragraph containing the search value if it exists
 * @returns the description element based on the existence of a paragraph containing the search value
 */
export const chooseDescription = (fields, searchValue, excerpt,) => {
  // type checks in case the description field is an object (e.g. from contentful's richtext)
  if (typeof fields.description === "string" && fields?.description?.includes(searchValue)) {
    return fields?.description
  } else {
    if (excerpt) {
      return `(...) ${excerpt} (...)`
    } else {
      return fields?.description?.content?.find(x => x.content).content.find(y => y.value).value || fields?.description;
    }
  }
}

/**
 * Format data received from contentful full-text search to populate the ResultCard component
 * @param {string} resultId - contentful content type (member, articles...)
 * @param {object} result - data received from contentful
 * @returns an object of details depending on the search result's content type
 */
export const generateResultDetails = (contentType, result, searchValue) => {
  const { fields } = result;
  const excerpt = findExcerptFromSearchValue(fields, contentType, searchValue);
  const type = formatContentTypes(contentType);
  const contentImage = fields?.article?.content || fields?.content?.content;
  const title = fields.titre || fields.title

  switch (contentType) {
    case "member":
      return {
        description: chooseDescription(fields, searchValue, excerpt) || fields.description,
        image: fields.img.fields.file.url,
        link: `/conseil-administratif#${result.sys.id}`,
        title: fields.title
      }
    case "articles":
    case "installation":
    case "formation":
      return {
        description: contentType === "formation" ? fields?.description : chooseDescription(fields, searchValue, excerpt) || findExcerptFromSearchValue(fields, contentType, searchValue),
        image: fields?.image?.fields?.file?.url || contentImage?.find(content => content.nodeType === "embedded-asset-block")?.data?.target?.fields?.file?.url,
        link: `/${type === 'article' ? 'actualites' : type === "installation" ? "installation" : "session"}/${fields.slug}`,
        title: title,
        date: fields?.date,
        author: fields?.name
      }
    case "videos":
      return {
        description: fields.description,
        link: `/${fields.page}#video`,
        title: fields.title,
        image: fields?.image?.fields?.file?.url
      }
    case "document":
    case "categories":
    case "subCategory":
      return {
        image: "pdfIcon",
        title: fields.title
      }
    default:
      return {
        description: "Description non disponible",
        link: '/',
        title: "Titre non disponible"
      }
  }
}
