import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({

  root: {
    width: '70%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    borderRadius: 0,
    margin: '2rem auto',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    '@media (max-width:850px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  highlighted: {
    background: theme.palette.secondary.main,
    color: 'inherit',
    fontWeight: 900
  },
  media: {
    maxWidth: 300,
    height: '100%',
    margin: 'auto'
  },
  cardContainer: {
    width: '40%',
    height: 250,
    '@media (max-width:850px)': {
      width: '100%'

    },

  },

  linkTitle: {
    color: theme.palette.primary.mainBGColor,
    fontSize: 20,
    textDecoration: 'underline'
  },

  MuiCardContent: {
    padding: 0
  },

  description: {
    color: theme.palette.primary.main,
    textAlign: 'justify'

  },

  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginLeft: '3%',
    width: '60%',
    '@media (max-width:850px)': {
      width: '100%',
      padding: 0,
      alignItems: 'inherit',
      marginLeft: 0
    },
  },

  resultDescription: {
    textAlign: 'justify'
  }


}));

