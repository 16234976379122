import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    background: fade(theme.palette.neutral.main, .05),
    border: '2px solid',
    borderRadius: 30,
    borderColor: ({ isPrimary }) => isPrimary ? fade(theme.palette.primary.mainBGColor, .7) : fade(theme.palette.secondary.mainBGColor, .7),
    maxWidth: '90%'
  }
}));