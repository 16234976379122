import React from 'react';
import latinize from 'latinize'
import PropTypes from 'prop-types';
import {Button} from "gatsby-theme-material-ui";
import {Box, Card, CardContent, CardMedia, Grid} from '@material-ui/core';
import Highlighter from "react-highlight-words";
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

import Icon from '../Icons/Icon';
import styles from './styles';

const ResultCard = ({description, image, link, title, searchValue, date, author, dispatchSearchSubCategory, subCategory, resultTitle}) => {
  const classes = styles();
  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.cardContainer}>
        {image === "pdfIcon"
          ? (
            <Grid container justify="center" alignItems="center">
              <Box my={4}>
                <Icon icon={faFilePdf} size="5x"/>
              </Box>
            </Grid>
          )
          : <CardMedia
            className={classes.media}
            image={image}
          />
        }
      </div>
      <Box className={classes.rightSection}>
        <CardContent>
          <Button
            className={classes.linkTitle}
            to={link}
            state={{description, image, link, title, date, author}}
            onClick={() => {
              dispatchSearchSubCategory({subCategory:subCategory, resultTitle:resultTitle})
            }}
          >
            <Highlighter
              highlightClassName={classes.highlighted}
              searchWords={[searchValue]}
              autoEscape={true}
              textToHighlight={title}
              sanitize={latinize}
            />
          </Button>
        </CardContent>
        <CardContent>
          <Highlighter
            highlightClassName={classes.highlighted}
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={description}
            sanitize={latinize}
          />
        </CardContent>
      </Box>
    </Card>
  );
}

export default ResultCard

ResultCard.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired
};

ResultCard.defaultProps = {
  description: "Ce contenu peut vous intéresser",
};
